import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects'
import axios from 'axios';
import { API_URL } from '../constants';

import { setCountries, setStates, setCities } from './data'
import { validateSessionSaga, initTriggerReload } from './init.saga';
import { getUserSaga, getUsersSaga } from './user.saga';


function* getCountries() {
    try {
        const countries = yield axios.get(`${API_URL}/countries`);
        if (countries.data) {
            yield put(setCountries(countries.data));
        } else {
            yield put(setCountries([]));
        }
    } catch (e) {

    }
}

function* getCountriesSaga() {
    yield takeLatest("GETCOUNTRIES", getCountries);
}

export function getCountriesAction() {
    return { type: 'GETCOUNTRIES' }
}

function* getStates({ payload }) {
    try {
        const states = yield axios.get(`${API_URL}/states/${payload}`);
        if (states.data) {
            yield put(setStates(states.data));
        } else {
            yield put(setStates([]));
        }
    } catch (e) {

    }
}

function* getStatesSaga() {
    yield takeLatest("GETSTATES", getStates);
}

export function getStatesAction(isoCodeCountry) {
    return { type: 'GETSTATES', payload: isoCodeCountry }
}


function* getCities({ payload }) {
    try {

        const states = yield axios.post(`${API_URL}/cities/`, payload);
        if (states.data) {
            yield put(setCities(states.data));
        } else {
            yield put(setCities([]));
        }
    } catch (e) {

    }
}

function* getCitiesSaga() {
    yield takeLatest("GETCITIES", getCities);
}

export function getCitiesAction(state) {
    return { type: 'GETCITIES', payload: state }
}




function* getInitData() {
    yield put(getCountriesAction())
}


export default function* rootSaga() {
    yield all([
        getCountriesSaga(),
        getStatesSaga(),
        getCitiesSaga(),
        getInitData(),
        validateSessionSaga(),
        initTriggerReload(),
        getUserSaga(),
        getUsersSaga()
        // forceCloseSessionSaga()
    ])
}